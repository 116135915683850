<template>
  <div class="pa-8">
    <v-text-field
      label="Form Name"
      persistent-placeholder
      v-model="$v.form.formName.$model"
      :error-messages="errorMessage()"
    ></v-text-field>

    <div class="build-wrap"></div>
  </div>
</template>
<style scoped src="@/css/form/edit.css"></style>
<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

window.$ = window.jQuery = require('jquery');
require('../../assets/jquery_plugin/jquery-ui.min');
require('../../assets/jquery_plugin/jquery_formbuilder_plugin/form-builder.min.js');
require('../../assets/jquery_plugin/jquery_formbuilder_plugin/form-render.min.js');

export default {
  name: 'EditForm',
  data: function () {
    return {      
      form: {
        formName: null
      },
      exceptFieldType: ["header", "paragraph"],
      formid: this.$route.params.idform,
      formData: null
    }
  },
  mixins: [validationMixin],
  validations:{
    form: {
      formName: {
        required
      }
    }
  },
  mounted: function(){
    this.getFormData();
  },
  computed: {
    isValid () { return !this.$v.form.$invalid }
  },
  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },
    buildForm: function(){
      var vueObj = this;
      var options = {        
        actionButtons: [{
          id: 'preview',
          className: 'btn btn-success',
          label: 'Preview',
          type: 'button',
          events: {
            click: function() {
              vueObj.showFormRender();
            }
          }
        }],
        formData: vueObj.formData
      }
      $(".build-wrap").formRender(options);
    },      
    async getFormData() {
      this.$store.commit('set', ['modalSpinnerText','Loading...']);
      this.$store.commit('toggle', 'showHideModalSpinner');

      const headers = {
        'Authorization': 'Bearer ' + window.$cookies.get("requestToken"),
        'Include-Form-String': 'true'
      }

      try {
        const data = (await this.$http.get('/api/form/' + this.formid, { headers })).data
        const str = atob(data.form_string)
        this.formData = JSON.parse(str)
        this.buildForm()
        this.form.formName = data.name
        this.formid = data.idform
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit('toggle', 'showHideModalSpinner');
      }
    },
    errorMessage() {
      const fName = this.$v.form.formName
      return (fName.$dirty && !fName.required) ? 'Required' : null
    }
  }
}
</script>
